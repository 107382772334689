<template>
  <b-container fluid>
    <bo-page-title />

    <b-card v-if="isList" no-body>
      <b-card-header>
        <b-row>
          <b-col lg="6">
            <h5 class="card-title">{{ pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row v-if="(data.data||[]).length">
            <b-col lg="12" v-for="(v,k) in data.data||[]" :key="k">
                <b-card body-class="bg-light" no-title header-tag="header" footer-tag="footer">
                    <div class="mb-2">
                        <a href="javascript:;"><span class="font-weight-semibold">{{v.an_data.level}}</span></a>          <span class="text-muted float-right font-size-sm">{{momentDate(v.an_created_date)}}</span>
                    </div>
                    <p>{{v.an_data.messages}}</p>
                    <span :class="v.an_status == 'D'?'text-muted float-right font-size-sm mt-3':'text-info float-right font-size-sm mt-3'">{{v.an_status == 'D' ? 'Belum Dibaca' : 'Sudah Dibaca'}}</span>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-else>
            <p class="text-center">Tidak ada Notifikasi</p>
        </b-row>
      </b-card-body>    
      <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&total>10">        
        <b-pagination
          class="mb-0"
          v-model="pageNo"
          :per-page="data.per_page"
          :total-rows="total"
        />
      </b-card-footer>
    </b-card>
  </b-container>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  extends: GlobalVue,
  data() {
    return {
      idKey:'an_id',
      statusKey:'an_is_active',
      notificationList: [],
      total: 0
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    apiGet(params = {}){
      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")
      if(params.slugs) slugs = params.slugs
      Gen.apiRest(
        "/get/"+this.modulePage+
        slugs, 
        {
          params:  {
            id : parseInt(this.user.id),
            levelId : parseInt(this.user.levelId),
            page: this.$route.query.page
          }
        }
      ).then(res=>{
        this.loadingOverlay = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        
        let id = []
        for (let i = 0; i < this.data.data.length; i++) {
            if(this.data.data[i].an_status == "D"){
            id.push(this.data.data[i].an_id)
            }
        }
        if(id.length){
            Gen.apiRest('/do/'+'Notification', {data: {type:'updateNotif', id: id}}, 'POST').then(()=>{
                this.apiGet()
            })
        }
      }).catch(()=>{
        this.loadingOverlay = false
      })
    },
    getNotification(){
      Gen.apiRest(
        "/get/"+"Notification",
        {
          params: {
            id : parseInt(this.user.id),
            levelId : parseInt(this.user.levelId),
          }
        }
      ).then(res=>{
        this.notificationList = res.data.data
        console.log(this.notificationList)
      })
    },
    momentDate(dates){
      let formatted = moment(dates).format("YYYYMMDDHHmm")
      let newDates = moment(formatted, "YYYYMMDDHHmm").fromNow()
      return newDates
    },
    doFill(){
      this.doFilter()
    }
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>